// Norne branding
import norneLightLogo from "./svg/norne/norne-logo-light.svg";
import norneBlueLogo from "./svg/norne/norne-logo-blue.svg";
import norneSingleWhiteLogo from "./svg/norne/norne-logo-single-white.svg";
import norneStockIcon from "./svg/norne/norne-aksjehandel-ikon.svg";
import nornePolesIcon from "./svg/norne/norne-soyle-ikon.svg";
import norneWavesIcon from "./svg/norne/norne-bolger-ikon.svg";
import norneFundIcon from "./svg/norne/norne-fond-ikon.svg";
import norneLineIcon from "./svg/norne/norne-linje-d-ikon.svg";
import norneLineBlueIcon from "./svg/norne/norne-line-blue.svg";
import norneMailIcon from "./svg/norne/norne-mail-ikon.svg";
import norneDotsDiagramIcon from "./svg/norne/norne-prikker-diagram-ikon.svg";
import norneDotsIcon from "./svg/norne/norne-prikker-ikon.svg";
import norneUserIcon from "./svg/norne/norne-bruker-ikon.svg";
import collapseSmallIcon from "./svg/norne/collapse-small.svg";
import expandSmallRightIcon from "./svg/norne/expand-small-right.svg";

import checkmarkCheckedIcon from "./svg/norne/checkmark-checked.svg";
import checkmarkEmptyIcon from "./svg/norne/checkmark-empty.svg";
import sustainabilityIcon from "./svg/norne/sustainability.svg";

import manConfirmingYellowIcon from "./svg/norne/man-confirming-yellow-icon.svg";
import manWithCheckboxesBlueIcon from "./svg/norne/man-with-checkboxes-blue-icon.svg";
import manWithChoicesYellowIcon from "./svg/norne/man-with-choices-yellow-icon.svg";
import manWithProgressBlueIcon from "./svg/norne/man-with-progress-blue-icon.svg";
import manWithProgressBarYellowIcon from "./svg/norne/man-with-progressbar-yellow-icon.svg";
import manWithGraphsYellowIcon from "./svg/norne/man-with-graphs-yellow-icon.svg";
import manWithMobileBlueIcon from "./svg/norne/man-with-mobile-blue-icon.svg";
import womanWithCatYellowIcon from "./svg/norne/woman-with-cat-yellow-icon.svg";
import womanWithCatBlueIcon from "./svg/norne/woman-with-cat-blue-icon.svg";
import womanWithGoalsYellowIcon from "./svg/norne/woman-with-goals-yellow-icon.svg";
import womanWithMobileRedIcon from "./svg/norne/woman-with-mobile-red-icon.svg";
import womanWithMobileYellowIcon from "./svg/norne/woman-with-mobile-yellow-icon.svg";
import womanWithPhoneAndNatureYellowIcon from "./svg/norne/woman-with-phone-and-nature-yellow-icon.svg";
import coupleSittingYellowIcon from "./svg/norne/couple-sitting-yellow-icon.svg";
import coupleYellowIcon from "./svg/norne/couple-yellow-icon.svg";
import coupleWithVespaIcon from "./svg/norne/couple-with-vespa-yellow.svg";
import familyBlueIcon from "./svg/norne/family-blue-icon.svg";
import familyYellowIcon from "./svg/norne/family-yellow-icon.svg";
import friendsYellowIcon from "./svg/norne/friends-yellow-icon.svg";
import interviewYellowIcon from "./svg/norne/interview-yellow-icon.svg";

import finautIcon from "./svg/finaut.svg";

// Hero
import ellipsisVerticalIcon from "./svg/hero/ellipsis-vertical.svg";
import arrowPathRoundedSquare from "./svg/hero/arrow-path-rounded-square.svg";
import chevronDownIcon from "./svg/hero/chevron-down.svg";
import chevronUpIcon from "./svg/hero/chevron-up.svg";
import barsIcon from "./svg/hero/bars.svg";
import xMarkIcon from "./svg/hero/x-mark.svg";
import bankNotesIcon from "./svg/hero/banknotes.svg";
import inboxIcon from "./svg/hero/inbox.svg";
import arrowRightOnRectangleIcon from "./svg/hero/arrow-right-on-rectangle.svg";
import arrowLeftOnRectangleIcon from "./svg/hero/arrow-left-on-rectangle.svg";
import circleStackIcon from "./svg/hero/circle-stack.svg";
import userHeroIcon from "./svg/hero/user.svg";
import checkIcon from "./svg/hero/check.svg";
import globeHeroIcon from "./svg/hero/globe-alt.svg";
import checkCircleIcon from "./svg/hero/check-circle.svg";
import shoppingCartIcon from "./svg/hero/shopping-cart.svg";
import arrowsRightLeftIcon from "./svg/hero/arrows-right-left.svg";
import trashIcon from "./svg/hero/trash.svg";
import arrowSmallLeftIcon from "./svg/hero/arrow-small-left.svg";
import arrowSmallRightIcon from "./svg/hero/arrow-small-right.svg";
import calendarDaysIcon from "./svg/hero/calendar-days.svg";
import funnelIcon from "./svg/hero/funnel.svg";
import magnifyingGlassIcon from "./svg/hero/magnifying-glass.svg";
import paperClipIcon from "./svg/hero/paper-clip.svg";
import printerIcon from "./svg/hero/printer.svg";
import pencilSquareIcon from "./svg/hero/pencil-square.svg";
import arrowUturnLeftIcon from "./svg/hero/arrow-uturn-left.svg";
import buildingOfficeIcon from "./svg/hero/building-office.svg";
import arrowLongLeftIcon from "./svg/hero/arrow-long-left.svg";
import arrowTrendingIcon from "./svg/hero/arrow-trending.svg";
import square2StackIcon from "./svg/hero/square-2-stack.svg";
import plusCircleIcon from "./svg/hero/plus-circle.svg";
import usersIcon from "./svg/hero/users.svg";
import questionMarkCircleIcon from "./svg/hero/question-mark-circle.svg";
import chevronRightIcon from "./svg/hero/chevron-right.svg";
import homeIcon from "./svg/hero/home.svg";
import rocketLaunchIcon from "./svg/hero/rocket-launch.svg";
import trophyIcon from "./svg/hero/trophy.svg";
import ellipsisHorizontalCircleIcon from "./svg/hero/ellipsis-horizontal-circle.svg";
import arrowDownTrayIcon from "./svg/hero/arrow-down-tray.svg";
import arrowRightIcon from "./svg/hero/arrow-right.svg";
import documentArrowDownIcon from "./svg/hero/document-arrow-down.svg";
import settingsIcon from "./svg/hero/settings.svg";
import pencilIcon from "./svg/hero/pencil.svg";
import arrowLongDown from "./svg/hero/arrow-long-down.svg";
import arrowLongUp from "./svg/hero/arrow-long-up.svg";
import chevronUpDown from "./svg/hero/chevron-up-down.svg";
import phoneIcon from "./svg/hero/phone.svg";
import chevronLeftIcon from "./svg/hero/chevron-left.svg";
import chevronDoubleLeftIcon from "./svg/hero/chevron-double-left.svg";
import chevronDoubleRightIcon from "./svg/hero/chevron-double-right.svg";
import arrowTopRightOnSquareIcon from "./svg/hero/arrow-top-right-on-square.svg";
import exclamationCircleIcon from "./svg/hero/exclamation-circle.svg";
import exclamationTriangleIcon from "./svg/hero/exclamation-triangle.svg";
import arrowTrendingUpIcon from "./svg/hero/arrow-trending-up.svg";
import clockIcon from "./svg/hero/clock.svg";
import starIcon from "./svg/hero/star.svg";
import starFilledIcon from "./svg/hero/starFilled.svg";
import giftTopIcon from "./svg/hero/gift-top.svg";
import ellipsisHorizontalIcon from "./svg/hero/ellipsis-horizontal.svg";
import arrowUpTrayIcon from "./svg/hero/arrow-up-tray.svg";
import bankLibraryIcon from "./svg/hero/bank-library.svg";
import bellAlertIcon from "./svg/hero/bell-alert.svg";
import bellAlertSolidIcon from "./svg/hero/bell-alert-solid.svg";
import bellIcon from "./svg/hero/bell.svg";
import bellSlashIcon from "./svg/hero/bell-slash.svg";
import arrowsPointingOut from "./svg/hero/arrows-pointing-out.svg";
import lightBulbIcon from "./svg/hero/light-bulb.svg";
import linkIcon from "./svg/hero/link.svg";
import documentIcon from "./svg/hero/document.svg";
import xCircleIcon from "./svg/hero/x-circle.svg";

// Font awesome
import facebookIcon from "./svg/fontawesome/facebook.svg";
import instagramIcon from "./svg/fontawesome/instagram.svg";
import linkedinIcon from "./svg/fontawesome/linkedin.svg";
import piggyBankIcon from "./svg/fontawesome/piggy-bank.svg";

import santaHatIcon from "./svg/santa-hat.svg";
import easterBunnyEarsIcon from "./svg/easter-bunny-ears.svg";
import easterBunnyFoldedEarsIcon from "./svg/easter-bunny-folded-ears.svg";
import easterBunnyPawsIcon from "./svg/easter-bunny-paws.svg";

import indicatorIcon from "./svg/norne/indicator.svg";

const Icons = {
  xCircleIcon,
  bellSlashIcon,
  bellAlertSolidIcon,
  ellipsisVerticalIcon,
  indicatorIcon,
  lightBulbIcon,
  finautIcon,
  piggyBankIcon,
  documentIcon,
  linkIcon,
  arrowsPointingOut,
  arrowPathRoundedSquare,
  bellIcon,
  bellAlertIcon,
  bankLibraryIcon,
  ellipsisHorizontalIcon,
  arrowUpTrayIcon,
  giftTopIcon,
  clockIcon,
  arrowTrendingUpIcon,
  santaHatIcon,
  easterBunnyEarsIcon,
  easterBunnyFoldedEarsIcon,
  easterBunnyPawsIcon,
  starIcon,
  starFilledIcon,
  exclamationTriangleIcon,
  exclamationCircleIcon,
  arrowTopRightOnSquareIcon,
  chevronLeftIcon,
  chevronDoubleLeftIcon,
  chevronDoubleRightIcon,
  pencilIcon,
  arrowRightIcon,
  documentArrowDownIcon,
  arrowDownTrayIcon,
  facebookIcon,
  linkedinIcon,
  instagramIcon,
  ellipsisHorizontalCircleIcon,
  trophyIcon,
  rocketLaunchIcon,
  homeIcon,
  chevronRightIcon,
  questionMarkCircleIcon,
  usersIcon,
  plusCircleIcon,
  square2StackIcon,
  arrowLongLeftIcon,
  buildingOfficeIcon,
  arrowUturnLeftIcon,
  pencilSquareIcon,
  printerIcon,
  paperClipIcon,
  magnifyingGlassIcon,
  funnelIcon,
  calendarDaysIcon,
  arrowSmallLeftIcon,
  arrowSmallRightIcon,
  trashIcon,
  arrowsRightLeftIcon,
  shoppingCartIcon,
  chevronDownIcon,
  chevronUpIcon,
  barsIcon,
  xMarkIcon,
  bankNotesIcon,
  inboxIcon,
  arrowRightOnRectangleIcon,
  arrowLeftOnRectangleIcon,
  arrowTrendingIcon,
  circleStackIcon,
  userHeroIcon,
  checkIcon,
  globeHeroIcon,
  checkCircleIcon,
  phoneIcon,
  settingsIcon,
  // Norne logos and icons
  norneLightLogo,
  norneBlueLogo,
  norneSingleWhiteLogo,
  norneStockIcon,
  nornePolesIcon,
  norneWavesIcon,
  norneFundIcon,
  norneLineIcon,
  norneLineBlueIcon,
  norneMailIcon,
  norneDotsDiagramIcon,
  norneDotsIcon,
  norneUserIcon,
  collapseSmallIcon,
  expandSmallRightIcon,
  checkmarkCheckedIcon,
  checkmarkEmptyIcon,
  arrowLongDown,
  arrowLongUp,
  chevronUpDown,
  sustainabilityIcon,
  manConfirmingYellowIcon,
  manWithCheckboxesBlueIcon,
  manWithChoicesYellowIcon,
  manWithGraphsYellowIcon,
  manWithMobileBlueIcon,
  manWithProgressBarYellowIcon,
  manWithProgressBlueIcon,
  womanWithCatBlueIcon,
  womanWithCatYellowIcon,
  womanWithGoalsYellowIcon,
  womanWithPhoneAndNatureYellowIcon,
  womanWithMobileYellowIcon,
  womanWithMobileRedIcon,
  coupleSittingYellowIcon,
  coupleWithVespaIcon,
  coupleYellowIcon,
  familyBlueIcon,
  familyYellowIcon,
  friendsYellowIcon,
  interviewYellowIcon,
} as const;

export default Icons;
