import icons from "./icons";
import { FC } from "react";
import { IIcon } from "./types";

const sizes = {
  xxsmall: "12px",
  xsmall: "16px",
  small: "24px",
  medium: "32px",
  large: "64px",
  xlarge: "72px",
  xxlarge: "92px",
  xxxlarge: "104px",
  obese: "125px",
  logo: "140px",
  image: "250px",
};

const Icon: FC<IIcon> = ({ name, size, wrapInSpan = false, hoverEffect = false }) => {
  const getIcon = () => {
    if (Object.keys(icons).includes(name)) {
      return icons[name];
    }
    console.error(`${name} not found, using fallback`);
    return "No Icon found";
  };

  const Comp = getIcon();

  if (Comp === "No Icon found") {
    return <div>{Comp}</div>;
  }

  const compClassName = hoverEffect ? ` hover:text-blueHighlighted` : "";

  return wrapInSpan ? (
    <span className={`inline-block`}>
      <Comp width={size ? sizes[size] : sizes.medium} heigth={size ? sizes[size] : sizes.medium} className={compClassName} />
    </span>
  ) : (
    <div>
      <Comp width={size ? sizes[size] : sizes.medium} heigth={size ? sizes[size] : sizes.medium} className={compClassName} />
    </div>
  );
};

export default Icon;
