export const Banks = new Map<string, string>([
  ["4484", "Aasen Sparebank"],
  ["2500", "Andebu Sparebank"],
  ["2895", "Arendal og Omegn Sparekasse"], // Agder sparebank
  ["1100", "Askim & Spydeberg Sparebank"],
  ["3745", "Aurland Sparebank"], // Sogn sparebank
  ["1271", "Aurskog Sparebank"],
  ["2601", "Skagerak Sparebank"],
  ["1105", "Berg Sparebank"],
  ["2880", "Birkenes Sparebank"],
  ["4295", "Bjugn Sparebank"],
  ["1321", "Blaker Sparebank"], // Romerike
  ["4075", "Romsdalsbanken"],
  ["1254", "Cultura Bank"],
  ["2635", "Drangedal Sparebank"],
  ["1020", "Eidsberg Sparebank"],
  ["3543", "Etne Sparebank"],
  ["2140", "Etnedal Sparebank"],
  ["2901", "Evje og Hornes Sparebank"],
  ["3411", "Fana Sparebank"],
  ["3755", "Fjaler Sparebank"],
  ["3030", "Flekkefjord Sparebank"],
  ["1450", "Fornebubanken"], // Oslofjord Sparebank
  ["2907", "Gjerstad Sparebank"], // Agder Sparebank
  ["4448", "Grong Sparebank"],
  ["1830", "Grue Sparebank"],
  ["4355", "Haltdalen Sparebank"],
  ["3240", "Haugesund Sparebank"],
  ["4465", "Hegra Sparebank"],
  ["2699", "Hjartdal og Gransherad Sparebank"],
  ["3353", "Hjelmeland Sparebank"],
  ["2333", "Hol Sparebank"], // Skue sparebank
  ["1280", "Høland Sparebank"],
  ["2230", "Hønefoss Sparebank"], // Skue sparebank
  ["3730", "Indre Sogn Sparebank"], // Sogn sparebank
  ["1440", "Jernbanepersonalets Bank"],
  ["3290", "Jæren Sparebank"],
  ["2655", "Kragerø Sparebank"], // Skagerrak sparebank
  ["3080", "Kvinesdal Sparebank"],
  ["2510", "Larvikbanken"],
  ["2850", "Lillesands Sparebank"],
  ["1286", "Romerike Sparebank"],
  ["4589", "Lofoten Sparebank"], // 68 grader nord
  ["3785", "Luster Sparebank"],
  ["1050", "Marker Sparebank"],
  ["4260", "Meldal Sparebank"], // Orkla
  ["4230", "Melhus Sparebank"],
  ["4106", "Nesset Sparebank"], // Romsdalsbanken
  ["4358", "Nidaros Sparebank"],
  ["0000", "Norne Securities"],
  ["9820", "OBOS BANK AS"],
  ["1870", "Odal Sparebank"],
  ["4605", "Ofoten Sparebank"], // 68 grader nord
  ["4266", "Oppdalsbanken"],
  ["4270", "Orkla Sparebank"],
  ["4111", "Rindal Sparebank"],
  ["4280", "Rørosbanken"],
  ["3305", "Sauda Sparebank"], // SPV
  ["4285", "Selbu Sparebank"],
  ["1338", "Setskog Sparebank"],
  ["3361", "Skudenes & Aakra Sparebank"],
  ["2351", "Skue Sparebank"],
  ["4333", "Soknedal Sparebank"],
  ["4730", "Sparebank 68° Nord"],
  ["1720", "Sparebanken Bien"],
  ["2630", "Sparebanken DIN"],
  ["4312", "Trøndelag Sparebank"], // Trøndelag Sparebank
  ["4520", "Sparebanken Narvik"],
  ["3890", "Sparebanken Sogn og Fjordane"],
  ["2811", "Sparebanken Sør"],
  ["3601", "Sparebanken Vest"],
  ["2220", "Sparebanken Øst"],
  ["3060", "Spareskillingsbanken"],
  ["1135", "Spydeberg Sparebank"], // Askim & Spydeberg Sparebank
  ["4336", "Stadsbygd Sparebank"],
  ["1310", "Strømmen Sparebank"],
  ["4035", "Sunndal Sparebank"],
  ["4040", "Surnadal Sparebank"], // Sparebank1 Nordmøre
  ["3090", "Søgne og Greipstad Sparebank"],
  ["3325", "Time Sparebank"], // Jæren sparebank
  ["2620", "Tinn Sparebank"],
  ["1885", "Tolga-Os Sparebank"],
  ["2050", "Totens Sparebank"],
  ["1140", "Trøgstad Sparebank"],
  ["3525", "Tysnes Sparebank"],
  ["2890", "Valle Sparebank"],
  ["2146", "Vang Sparebank"], // Valdres Sparebank
  ["2153", "Valdres Sparebank"],
  ["1010", "Vibrand"],
  ["3800", "Vik Sparebank"], // Sogn sparebank
  ["3480", "Voss Sparebank"],
  ["4290", "Ørland Sparebank"],
  ["4060", "Ørskog Sparebank"],
  ["4345", "Trøndelag Sparebank"], // Trøndelag sparebank
  ["4530", "Helgeland Sparebank"],
  ["9090", "Eika Kapitalforvaltning"],
  ["3910", "Sparebanken Møre"],
  ["3030", "Flekkefjord Sparebank"],
  ["3260", "Rogaland Sparebank"],
]);

export const BANKS_WITH_SECONDARY_LANGUAGE = ["3480", "3890"];
export const NORNE_CENTERID = "0000";
export const NORNE_BANK_ACCOUNT_NUMBER = "3624.27.98605";
